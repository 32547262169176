import React from "react"
import styled from "styled-components"

const Title = ({ title, subtitle, published_at }) => {
  return (
    <Wrapper>
      <div className="section-title">
        <h2 className="section-title-text">{title}</h2>
        {subtitle && <div className="subtitle">{subtitle}</div>}
        {published_at && (
          <div className="published-at">{published_at.toUpperCase()}</div>
        )}
      </div>
    </Wrapper>
  )
}

export default Title

const Wrapper = styled.section`
  .section-title {
    margin-bottom: 2rem;
    text-align: center;
  }
  .subtitle {
    color: var(--clr-grey-2);
    font-size: 2rem;
  }

  @media screen and (min-width: 760px) {
    .section-title {
      text-align: left;
    }
  }
`
