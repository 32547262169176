// Search.js

import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Breadcrumbs from "./navigation/Breadcrumbs"
import algoliasearch from "algoliasearch/lite"
import {
  InstantSearch,
  SearchBox,
  connectHits,
  connectStateResults,
  PoweredBy,
} from "react-instantsearch-dom"
import crumbsToUrl from "./../utils/crumbsToUrl"

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)
const NewHits = connectHits(({ hits }) => {
  return hits.map(item => {
    const { objectID, title, breadcrumbs, published_at, slug, metaPageDesc } =
      item
    const currentPage = {
      slug,
      title,
    }
    const url = crumbsToUrl(breadcrumbs, slug)

    return (
      <article key={objectID}>
        <h3>
          <Link to={url} className="title-link">
            {title}
          </Link>
        </h3>
        {breadcrumbs ? (
          <Breadcrumbs breadcrumbs={breadcrumbs} currentPage={currentPage} />
        ) : (
          <div className="published">
            News article published: {published_at}
          </div>
        )}

        <p>{metaPageDesc}</p>
      </article>
    )
  })
})

const Search = () => {
  const Results = connectStateResults(
    ({ searchState, searchResults, children }) =>
      !searchState || !searchState.query ? (
        <div className="feedback">Enter search query in box above</div>
      ) : searchResults && searchResults.nbHits !== 0 ? (
        children
      ) : (
        <div className="feedback">
          No results found for search: <strong>"{searchState.query}"</strong>.
        </div>
      )
  )

  return (
    <Wrapper>
      <InstantSearch
        indexName={process.env.GATSBY_ALGOLIA_INDEX_NAME}
        searchClient={searchClient}
      >
        <div className="search-input">
          <SearchBox />
          <PoweredBy />
        </div>
        <Container className="section-center">
          <Results>
            <NewHits />
          </Results>
        </Container>
      </InstantSearch>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  padding-top: 5rem;
  padding-bottom: 15rem;
  .search-input {
    width: 90vw;
    max-width: 400px;
    margin: 0 auto;
    margin-bottom: 2rem;
  }
  .ais-SearchBox {
    /* width: 90vw;
    max-width: 400px;
    margin: 0 auto;
    margin-bottom: 2rem; */
    form {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 3rem;
      button {
        border: transparent;
        font-size: 1.3rem;
        border-top-right-radius: var(--radius-large);
        border-bottom-right-radius: var(--radius-large);
      }
      input {
        font-size: 1.4rem;
        border: transparent;
        padding: 1.5rem 1.2rem;
        background-color: var(--clr-grey-7);
        border-bottom-left-radius: var(--radius-large);
        border-top-left-radius: var(--radius-large);

        :focus {
          outline: none;
        }
      }
      button {
        background: var(--clr-grey-7);
        svg {
          fill: var(--clr-primary-1);
        }
      }
      button.ais-SearchBox-reset {
        display: none;
      }
    }
  }
  .ais-PoweredBy {
    /* text-align: right; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
    span {
      font-size: 1rem;
      color: var(--clr-grey-4);
      padding-right: 0.5rem;
    }
  }
  .ais-PoweredBy-logo {
    width: 5rem;
    height: auto;
  }
  .feedback {
    text-align: center;
  }
`

const Container = styled.div`
  article {
    padding: 2rem 3rem;
    margin: 1.5rem;
    background: var(--clr-white);
    text-align: left;
    border-radius: var(--radius);
    box-shadow: var(--ligth-shadow);
    transition: var(--transition);
    &:hover {
      transform: scale(1.001);
      box-shadow: var(--dark-shadow);
    }
    .published {
      font-size: 1.6rem;
      margin: 1.5rem 0 1rem 0;
    }
  }
  @media screen and (min-width: 760px) {
    article .published {
      font-size: 1.4rem;
    }
  }
`

export default Search
