const crumbsToUrl = (breadcrumbs, slug) => {
  //   console.log(breadcrumbs)
  let path = "/"

  breadcrumbs
    ? breadcrumbs.map(crumb => {
        path += crumb.slug + "/"
        return "done"
      })
    : (path += "news/")

  const url = path + slug
  return url
}

export default crumbsToUrl
