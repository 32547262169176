import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const Breadcrumbs = ({ breadcrumbs, currentPage }) => {
  let crumbPath = ""

  return (
    <Wrapper>
      {breadcrumbs && (
        <nav>
          <ul className="breadcrumbs">
            <li key="home" className="breadcrumb-item breadcrumb-first">
              <Link to="/">Home</Link>
            </li>
            {breadcrumbs.map(crumb => {
              crumbPath += "/" + crumb.slug
              return (
                <li key={crumb.slug} className="breadcrumb-item">
                  <Link to={crumbPath} className="breadcrumb-link">
                    {crumb.title}
                  </Link>
                </li>
              )
            })}
            <li
              key={currentPage.slug}
              className="breadcrumb-item breadcrumb-last"
            >
              <span aria-current="page">{currentPage.title}</span>
            </li>
          </ul>
        </nav>
      )}
    </Wrapper>
  )
}

export default Breadcrumbs

const Wrapper = styled.section`
  padding-top: 0.5rem;
  padding-bottom: 2rem;
  ul.breadcrumbs {
    display: flex;
    font-size: 1.6rem;
  }
  .breadcrumb-item {
    display: flex;
    align-items: center;
  }
  .breadcrumb-item::before {
    content: " > ";
  }
  .breadcrumb-first::before {
    content: "";
  }
  .breadcrumb-item a,
  .breadcrumb-last span {
    padding: 0.5rem 2rem;
    font-weight: normal;
  }
  .breadcrumb-first a {
    padding-left: 0;
  }

  @media screen and (min-width: 760px) {
    ul.breadcrumbs {
      font-size: 1.4rem;
    }
  }
`
