import React from "react"
import Layout from "../components/Layout"
import Search from "../components/Algolia"
import Seo from "../components/Seo"
import Title from "../components/Title"
import Breadcrumbs from "../components/navigation/Breadcrumbs"

const SearchPage = ({ searchTerm }) => {
  const currentPage = { slug: "search", title: "Search" }
  return (
    <Layout>
      <Seo
        title="Search results"
        description="Algolia search results"
        noIndex={true}
      />
      <div className="page-margins">
        <div className="page-template">
          <Breadcrumbs breadcrumbs={[]} currentPage={currentPage} />
          <Title title="Search" />
          <div className="columns">
            <div className="left-column">
              <Search />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SearchPage
